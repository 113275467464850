import React from "react";

import Container from "../Container";

import * as styles from "./styles.module.scss";

export default function HeroSection({ upperHeadline, title, description }) {
  return (
    <section className={styles.wrapper}>
      <Container>
        <div className={styles.content}>
          <h6>{upperHeadline}</h6>
          <div dangerouslySetInnerHTML={{ __html: title }} />
          {description && <p>{description}</p>}
        </div>
      </Container>
    </section>
  );
}
