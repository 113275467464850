import React, { useState } from "react";

import { Link } from "gatsby";

import { Button } from "@react-md/button";

import * as styles from "./styles.module.scss";

const NUMBER_OF_ARTICLES_TO_SHOW = 9;

export default function Articles({ articles, showButton = true }) {
  const [maxLength, setMaxLength] = useState(NUMBER_OF_ARTICLES_TO_SHOW);

  const handleShowMore = () => {
    setMaxLength((prev) => prev + NUMBER_OF_ARTICLES_TO_SHOW);
  };

  if (!articles.length)
    return (
      <div className={styles.noArticles}>
        <h5>No articles yet...</h5>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.gridWrapper}>
        {articles.map((post, index) => {
          if (index >= maxLength) return null;
          return (
            <Link key={post.slug} to={post.slug}>
              <article className={styles.post}>
                <img
                  src={post.cover.url}
                  alt={post.cover.alt ?? "Alternative text"}
                />
                <small>{post.createdAt}</small>
                <h3>{post.title}</h3>
                <p>{post.introduction}</p>
              </article>
            </Link>
          );
        })}
      </div>
      {showButton && !(maxLength >= articles.length) && (
        <Button className="btn" onClick={handleShowMore}>
          LOAD MORE
        </Button>
      )}
    </div>
  );
}
