import React from "react";

import Header from "../../components/layout/header";
import HeroSection from "../../components/HeroSection";
import Articles from "../../components/Articles";
import Footer from "../../components/layout/footer";

import facebookIcon from "../../assets/icons/facebook.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import linkedInIcon from "../../assets/icons/linkedIn.svg";

import { Helmet } from "react-helmet";

import * as styles from "./styles.module.scss";

export default function BlogPost({ pageContext }) {
  const { seoTitle, seoDescription, cover, title, content, relatedArticles } =
    pageContext;

  const isBrowser = typeof window !== "undefined";

  const url = isBrowser ? window.location.href : "https://defesenpoint.com";

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`;
  const twitterUrl = `https://twitter.com/share?url=${encodeURIComponent(url)}`;

  const linkedInUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    url
  )}`;

  return (
    <>
      <Helmet>
        <title>DefensePoint - {seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
      <Header />
      <HeroSection upperHeadline="LEARNING CENTER" title={title} />
      <div className={styles.wrapper}>
        <img className={styles.coverImage} src={cover.url} alt={cover.alt} />
        <div
          className={styles.contentWrapper}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className={styles.shareSocialMedia}>
          <span>Share:</span>
          <div className={styles.socialMediaIcons}>
            <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
              <img src={linkedInIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>

        <div className={styles.relatedArticlesWrapper}>
          <h2>Related blogs</h2>
          <Articles articles={relatedArticles} showButton={false} />
        </div>
      </div>
      <Footer />
    </>
  );
}
